import "@event-calendar/core/index.css";
import React from "react";
import { renderToStaticMarkup } from "react-dom/server.browser";
import { useLoaderData } from "react-router-dom";
import ECal from "@event-calendar/core";
import ResourceTimeGridPlugin from "@event-calendar/resource-time-grid";
import InteractionPlugin from "@event-calendar/interaction";
import * as api from "../services/api";
import {
  Select,
  MenuItem,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Box,
  DialogActions,
  IconButton,
} from "@mui/material";
import * as f from "../utils/formatter";
import * as ws from "../utils/ws";
import { useTranslation } from "react-i18next";
import ButtonLoader from "../components/ButtonLoader";
import * as calUtil from "shared/src/calendar.mjs";
import * as d from "shared/src/date.mjs";
import { throttle } from "shared/src/util.mjs";
import AutocompleteSelect from "../components/AutocompleteSelect";
import { THROTTLE_REFRESH } from "../constants";
import {
  createClickHandler,
  eventStyle,
  ServiceInfo,
} from "../components/calendar";
import {
  NewReservationDialog,
  NewReservationSuggestionDialog,
} from "../components/new-reservation-dialog";
import { useLatest } from "../hooks";
import { DatePicker } from "../components/DatePicker";
import Link from "../components/Link";
import {
  currentLocationFromRequest,
  useCurrentLocation,
} from "../components/LocationSelector";

/**
 * @typedef EventCalendar
 * @property {() => Event[]} getEvents
 * @property {(e: Event) => EventCalendar} updateEvent
 *
 * @typedef Appointment
 * @property {string} start
 * @property {string} end
 * @property {number} status_id
 * @property {string} calendar_id
 *
 * @typedef Calendar
 * @property {string} id
 * @property {string} title
 * @property {string} location_id
 *
 * @typedef Resource
 * @property {Calendar} extendedProps
 *
 * @typedef Event
 * @property {Date} start
 * @property {Date} end
 * @property {Appointment} extendedProps
 *
 */

export async function loader({ request }) {
  const location_id = currentLocationFromRequest(request);

  return api.loadCalendarData({
    calendarFilter: {
      visible: { eq: true },
      location_id: { eq: location_id },
    },
  });
}

function EventContent({ appointment }) {
  return (
    <>
      <div>{f.fullName(appointment.client)}</div>
      <ServiceInfo service={appointment.service} />
      <div>{appointment.description}</div>
    </>
  );
}

/**
 * @param {object} opts
 * @param {HTMLElement} opts.el
 * @param {React.MutableRefObject<string | undefined>} opts.location_idRef
 * @param {(event: object | null) => void} opts.setCreateEvent
 * @param {(event: object | null) => void} opts.setEditEvent
 */
function createEcal({ el, setCreateEvent, setEditEvent, location_idRef }) {
  const clickHandler = createClickHandler({
    onClick: (info) => {
      setEditEvent(info.event);
    },
    onDoubleClick: (info) => {
      const clientId = info.event.extendedProps?.client?.id;
      if (clientId == null) {
        return;
      }

      window.open(`/clients/${clientId}`, "_blank");
    },
  });

  /** @type {EventCalendar} */
  const cal = new ECal({
    target: el,
    props: {
      plugins: [ResourceTimeGridPlugin, InteractionPlugin],
      options: {
        view: "resourceTimeGridDay",
        nowIndicator: true,
        headerToolbar: { start: "", center: "", end: "" },
        slotDuration: "00:10",
        slotLabelFormat: { timeStyle: "short", hour12: false },
        allDaySlot: false,
        scrollTime: "09:00",
        eventTextColor: "#000",
        eventDurationEditable: false,
        eventDrop: async (info) => {
          const event = info.event;
          const newData = {
            id: event.id,
            start: d.toString(event.start),
            end: d.toString(event.end),
            ...(info.newResource == null
              ? {}
              : { calendar_id: info.newResource.id }),
          };

          const result = await api.patchAppointment(newData);
          if (result.errors != null) {
            info.revert();
          }
        },
        eventClassNames: (info) =>
          `event-id-${info.event.id} status-${info.event.extendedProps.status_id}`,
        eventContent: (info) => {
          return {
            html: `<div class="calendar-event" style="${eventStyle(info.event)}">
              ${info.event.title}
            </div>`,
          };
        },
        dateClick: (info) => {
          setCreateEvent({
            from: f.dateTimeInput(info.date),
            calendar_id: info.resource.id,
          });
        },
        eventClick: (info) => {
          clickHandler(info);
        },
        eventSources: [
          {
            events: async function (fetchInfo) {
              const location_id = location_idRef.current;
              if (location_id == null) {
                return [];
              }

              const resp = await api.loadCalendarAppointments({
                filter: {
                  start: {
                    gte: d.toString(fetchInfo.start),
                    lt: d.toString(fetchInfo.end),
                  },
                  location_id: { eq: location_id },
                },
              });

              return resp.data.appointments.data.map((e) => {
                const event = {
                  ...e,
                  title: renderToStaticMarkup(<EventContent appointment={e} />),
                  resourceId: e.calendar_id,
                  start: new Date(e.start),
                  end: new Date(e.end),
                  extendedProps: e,
                };

                return event;
              });
            },
          },
        ],
      },
    },
  });

  return cal;
}

const Cal = React.memo(function Cal({
  date,
  setCreateEvent,
  setEditEvent,
  cellHeight,
  location_id,
  location,
  resources,
}) {
  const cal = React.useRef();
  const ecalEl = React.useRef();
  const location_idRef = React.useRef();

  React.useEffect(() => {
    const refresh = throttle(() => {
      cal.current?.refetchEvents();
    }, THROTTLE_REFRESH);

    function maybeRefresh(msg) {
      if (msg.type === "appointment_changed") {
        refresh();
      }
    }

    ws.subscribe(maybeRefresh);

    return () => {
      ws.unsubscribe(maybeRefresh);
    };
  }, []);

  React.useEffect(() => {
    location_idRef.current = location_id;
    cal.current?.refetchEvents();
  }, [location_id]);

  React.useEffect(() => {
    cal.current = createEcal({
      el: ecalEl.current,
      setCreateEvent,
      setEditEvent,
      location_idRef,
    });

    return function () {
      cal.current.destroy();
      cal.current = null;
    };
  }, []);

  React.useEffect(() => {
    const c = cal.current;
    if (c == null) {
      return;
    }

    c.setOption("slotHeight", cellHeight);
    document.body.style.setProperty("--app-ec-slot-height", `${cellHeight}px`);
  }, [cal.current, cellHeight]);

  React.useEffect(() => {
    if (cal.current == null) {
      return;
    }

    cal.current.setOption("resources", resources);
  }, [resources]);

  React.useEffect(() => {
    const c = cal.current;
    if (c == null) {
      return;
    }

    const d = new Date(date);
    const day = calUtil.dateToDay(d);

    const wh = calUtil.workingHoursInDay(d, location?.working_hours?.[day]);
    const slotMinTime = wh?.from ?? "00:00";
    const slotMaxTime = wh?.to ?? "24:00";

    c.setOption("date", date);
    c.setOption("slotMinTime", slotMinTime);
    c.setOption("slotMaxTime", slotMaxTime);
  }, [date, resources]);

  return <div id="ecal" ref={ecalEl} />;
});

/**
 * @param {object} props
 * @param {Event} props.editEvent
 * @param {(event?: Event) => void} props.setEditEvent
 * @param {string} props.location_id
 */
function EventDialog({
  setEditEvent,
  editEvent,
  appointmentStatuses,
  location_id,
}) {
  const { t } = useTranslation();
  const client = editEvent.extendedProps.client;
  const [loading, setLoading] = React.useState(false);
  const serviceFilter = React.useMemo(() => {
    return { location_id: { eq: location_id } };
  }, [location_id]);
  const so = useServiceOptions(serviceFilter);

  return (
    <Dialog open={true} onClose={() => setEditEvent(null)}>
      <DialogTitle>{t("calendar.clientReservation")}</DialogTitle>
      <DialogContent>
        {client && (
          <>
            <Link to={`/clients/${client.id}`} target="_blank">
              {f.fullName(client)}
            </Link>
            <br />
          </>
        )}
        <Box display="flex" flexDirection="column" gap="1rem">
          <Select
            value={editEvent.extendedProps.status_id}
            onChange={async (e) => {
              setEditEvent(null);
              await api.patchAppointment({
                id: editEvent.id,
                status_id: e.target.value,
              });
            }}
            sx={{ width: "100%" }}
            disabled={loading}
          >
            {appointmentStatuses.map((s) => (
              <MenuItem key={s.id} value={s.id}>
                {s.name}
              </MenuItem>
            ))}
          </Select>
          <AutocompleteSelect
            required
            value={editEvent.extendedProps.service}
            loading={so.loading}
            options={so.options}
            disabled={loading}
            onChange={async (value) => {
              const ev = editEvent.extendedProps;
              if (value == null || value.id === ev.service.id) {
                return;
              }

              const fromDate = new Date(ev.start);
              const toDate = new Date(fromDate.getTime());
              toDate.setMinutes(toDate.getMinutes() + value.duration);

              setEditEvent(null);
              await api.patchAppointment({
                id: editEvent.id,
                service_id: value.id,
                start: d.toString(fromDate),
                end: d.toString(toDate),
              });
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          disabled={loading}
          onClick={async () => {
            setLoading(true);
            await api.deleteAppointment({ id: editEvent.id });
            setEditEvent(null);
            setLoading(false);
          }}
        >
          {loading && <ButtonLoader />}
          {t("buttons.delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

async function fetchServices(signal, filter) {
  if (filter == null) {
    return [];
  }

  const res = await api.serviceOptions(
    {
      filter: filter,
    },
    { signal },
  );

  return res.data.services.data.map((s) => ({
    ...s,
    name: `${s.name} (${f.minutesToDuration(s.duration)})`,
  }));
}

function useServiceOptions(filter) {
  const [options, setOptions] = React.useState();
  useLatest(
    async (signal) => {
      setOptions(null);
      setOptions(await fetchServices(signal, filter));
    },
    [filter],
  );

  return { options: options ?? [], loading: options == null };
}

const Toolbar = React.memo(function Toolbar({ setState, date, cellHeight }) {
  const { t } = useTranslation();
  const setShowNewReservationDialog = (showNewReservationDialog) =>
    setState((state) => ({ ...state, showNewReservationDialog }));
  const setShowYearDialog = (showYearDialog) =>
    setState((state) => ({ ...state, showYearDialog }));
  const setCellHeight = (cellHeight) =>
    setState((state) => ({ ...state, cellHeight }));

  return (
    <Box display="flex" gap={2} marginBottom={2}>
      <TextField
        label={t("calendar.dateLabel")}
        type="date"
        onChange={(e) => {
          const newDate = e.currentTarget.value;
          if (newDate.length === 0) {
            return;
          }

          setState((state) => ({ ...state, date: newDate }));
        }}
        value={date}
        sx={{ maxWidth: 200 }}
        slotProps={{
          inputLabel: { shrink: true },
        }}
      />
      <Button onClick={() => setShowNewReservationDialog(true)}>
        {t("calendar.newReservation")}
      </Button>
      <Button onClick={() => setShowYearDialog(true)}>
        {t("calendar.year")}
      </Button>
      {cellHeight === 24 ? (
        <IconButton onClick={() => setCellHeight(48)}>&#9047;</IconButton>
      ) : (
        <IconButton onClick={() => setCellHeight(24)}>&#9040;</IconButton>
      )}
    </Box>
  );
});

function initialCalendarState() {
  return {
    date: f.dateInput(new Date()),
    editEvent: null,
    createEvent: null,
    showNewReservationDialog: false,
    showYearDialog: false,
    cellHeight: 24,
  };
}

export default function Calendar() {
  const loaderData = useLoaderData();
  const appointmentStatuses = loaderData.data.appointmentStatuses.data;
  const locations = loaderData.data.locations.data;
  const resources = loaderData.data.calendars.data;
  const [state, setState] = React.useState(initialCalendarState);
  const location_id = useCurrentLocation();

  const {
    date,
    editEvent,
    createEvent,
    showNewReservationDialog,
    showYearDialog,
    cellHeight,
  } = state;
  const setEditEvent = React.useCallback(
    (editEvent) => setState((state) => ({ ...state, editEvent })),
    [setState],
  );
  const setCreateEvent = React.useCallback(
    (createEvent) => setState((state) => ({ ...state, createEvent })),
    [setState],
  );
  const setShowNewReservationDialog = React.useCallback(
    (showNewReservationDialog) =>
      setState((state) => ({ ...state, showNewReservationDialog })),
    [setState],
  );
  const setShowYearDialog = React.useCallback(
    (showYearDialog) => setState((state) => ({ ...state, showYearDialog })),
    [setState],
  );

  const location = locations.find((l) => l.id === location_id);

  return (
    <>
      <Toolbar cellHeight={cellHeight} date={date} setState={setState} />
      <Cal
        location={location}
        location_id={location_id}
        date={date}
        setCreateEvent={setCreateEvent}
        setEditEvent={setEditEvent}
        cellHeight={cellHeight}
        resources={resources}
      />
      {editEvent && (
        <EventDialog
          setEditEvent={setEditEvent}
          editEvent={editEvent}
          appointmentStatuses={appointmentStatuses}
          location_id={location_id}
        />
      )}
      {createEvent && (
        <NewReservationDialog
          defaults={createEvent}
          close={() => setCreateEvent(null)}
          location_id={location_id}
        />
      )}
      {showNewReservationDialog && (
        <NewReservationSuggestionDialog
          close={() => setShowNewReservationDialog(false)}
        />
      )}
      {showYearDialog && (
        <DatePicker
          close={() => setShowYearDialog(false)}
          onChange={(val) => {
            setState((state) => ({
              ...state,
              date: val,
              showYearDialog: false,
            }));
          }}
        />
      )}
    </>
  );
}
