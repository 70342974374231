import React from "react";
import { DOUBLE_CLICK_DELAY } from "../constants";

/**
 * @typedef Appointment
 * @property {string} start
 * @property {string} end
 * @property {number} status_id
 * @property {string} calendar_id
 *
 * @typedef Event
 * @property {Date} start
 * @property {Date} end
 * @property {string} backgroundColor
 * @property {Appointment} extendedProps
 */

/**
 * @param {{status_id: number, service: {color: string}}} appointment
 *
 * @returns {string}
 */
export function appointmentBackground(appointment) {
  return appointment.service?.color;
}

/**
 * @param {Event} event
 *
 * @returns {string}
 */
export function eventStyle(event) {
  return `border-color: ${appointmentBackground(event.extendedProps)}`;
}

export function createClickHandler({ onClick, onDoubleClick }) {
  let timeoutId = null;
  const clear = () => {
    clearTimeout(timeoutId);
    timeoutId = null;
  };

  return function (e) {
    if (timeoutId == null) {
      timeoutId = setTimeout(() => {
        clear();
        onClick(e);
      }, DOUBLE_CLICK_DELAY);
      return;
    }

    clear();
    onDoubleClick(e);
  };
}

export function ServiceInfo({ service }) {
  return (
    <div
      style={{
        border: "1px solid black",
        borderRadius: "4px",
        padding: "2px 4px",
        textWrap: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      <div
        style={{
          display: "inline-block",
          verticalAlign: "middle",
          backgroundColor: service?.color,
          width: "1em",
          height: "1em",
        }}
      />
      &nbsp;
      {service?.name}
    </div>
  );
}
