import { useTranslation } from "react-i18next";
import { useRevalidator } from "react-router-dom";
import React, { useState } from "react";
import { formData, FormErrorList, useFormAction } from "../../form.jsx";
import {
  createServiceTemplate,
  updateServiceTemplate,
} from "../../services/api.js";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import ButtonLoader from "../ButtonLoader.jsx";
import { mockedTaskTemplates } from "../client/AppointmentNotesTabContent.jsx";

export const ServiceTemplateDialog = ({
  setEditServiceTemplate,
  editServiceTemplate,
}) => {
  const { t } = useTranslation();
  const revalidator = useRevalidator();
  const [templatePayload, setTemplatePayload] = useState({
    schema: mockedTaskTemplates[0].schema,
    uischema: mockedTaskTemplates[0].uischema,
  });

  const handleChange = (e) => {
    setTemplatePayload((currentValue) => ({
      ...currentValue,
      [e.target.name]: JSON.parse(e.target.value),
    }));
  };
  const formAction = editServiceTemplate.templates
    ? updateServiceTemplate
    : createServiceTemplate;
  const { loading, errors, onSubmit } = useFormAction(
    (e) => {
      const formInput = formData(e.currentTarget);
      return formAction({
        service_id: formInput.serviceId,
        version: formInput.version,
        template: templatePayload,
      });
    },
    () => {
      revalidator.revalidate();
      setEditServiceTemplate(null);
    },
  );

  return (
    <Dialog
      open={true}
      onClose={() => setEditServiceTemplate(null)}
      PaperProps={{
        component: "form",
        onSubmit: onSubmit,
        sx: { minWidth: "600px" },
      }}
    >
      <DialogTitle>
        {editServiceTemplate.templates
          ? t("service.editTemplateTitle")
          : t("service.createTemplateTitle")}
      </DialogTitle>

      <input type="hidden" name="serviceId" value={editServiceTemplate.id} />
      <input type="hidden" name="version" value="1" />
      <DialogContent>
        <Box display="flex" flexDirection="column" gap="1rem">
          <TextField
            sx={{ my: 1, width: "100%" }}
            name="schema"
            id="schema"
            label={t("services.templateDialog.schemaLabel")}
            onChange={handleChange}
            value={JSON.stringify(templatePayload.schema)}
            multiline
            rows="4"
            slotProps={{ input: { sx: { fontFamily: "monospace" } } }}
          />
          <TextField
            sx={{ my: 1, width: "100%" }}
            name="uischema"
            id="uischema"
            label={t("services.templateDialog.uiSchemaLabel")}
            onChange={handleChange}
            value={JSON.stringify(templatePayload.uischema)}
            multiline
            rows="4"
            slotProps={{ input: { sx: { fontFamily: "monospace" } } }}
          />
          <FormErrorList formErrors={errors} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setEditServiceTemplate(null)}>
          {t("form.cancel")}
        </Button>
        <Button type="submit" disabled={loading}>
          {loading && <ButtonLoader />}
          {t("form.submit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
