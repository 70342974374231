import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Grid2, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as api from "../../services/api";
import NotesForReceptionForm from "./NotesForReceptionForm.jsx";
import AppointmentTasks from "./AppointmentTasks.jsx";
import { useWizardContext } from "./AppointmentWizardContext";

const SHORT_DURATION_THRESHOLD = 40;

export const mockedTaskTemplates = [
  {
    id: "template22key",
    schema: {
      type: "object",
      serviceType: "První konzultace",
      title: "First Consultation",
      description: "",
      properties: {
        clientMotivation: {
          type: "string",
          medicalDocumentation: "yes",
        },
        treatmentLimitations: {
          type: "string",
          medicalDocumentation: "yes",
        },
        consentExtractions: {
          type: "string",
          enum: ["yes", "no", "notAddressed"],
          medicalDocumentation: "yes",
        },
        possibleExtractionsAndLocalization: {
          type: "string",
          medicalDocumentation: "yes",
        },
        veneers: {
          type: "string",
          enum: ["yes", "no", "notAddressed"],
          medicalDocumentation: "yes",
        },
        veneersLocalization: {
          type: "string",
          medicalDocumentation: "yes",
        },
        implants: {
          type: "string",
          enum: ["yes", "no", "notAddressed"],
          medicalDocumentation: "yes",
        },
        implantsLocalization: {
          type: "string",
          medicalDocumentation: "yes",
        },
        otherProsthetics: {
          type: "string",
          enum: ["yes", "no", "notAddressed"],
          medicalDocumentation: "yes",
        },
        prostheticsLocalization: {
          type: "string",
          medicalDocumentation: "yes",
        },
        priority: {
          type: "string",
        },
        fixedText: {
          type: "string",
          medicalDocumentation: "yes",
        },
      },
      required: [
        "clientMotivation",
        "consentExtractions",
        "possibleExtractionsAndLocalization",
        "veneers",
        "veneersLocalization",
        "implants",
        "implantsLocalization",
        "otherProsthetics",
        "prostheticsLocalization",
      ],
    },
    uischema: {
      type: "VerticalLayout",
      elements: [
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "Control",
              scope: "#/properties/clientMotivation",
              label: "Client Motivation",
              options: { multi: true },
            },
            {
              type: "Control",
              scope: "#/properties/treatmentLimitations",
              label: "Treatment Limitations",
              options: { multi: true },
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "Control",
              scope: "#/properties/consentExtractions",
              label: "Consent for Extractions",
              options: { format: "enum" },
            },
            {
              type: "Control",
              scope: "#/properties/possibleExtractionsAndLocalization",
              label: "Possible Extractions and Their Localization",
              options: { multi: true },
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/veneers",
                  label: "Veneers",
                  options: { format: "enum" },
                },
                {
                  type: "Control",
                  scope: "#/properties/veneersLocalization",
                  label: "Veneers' Localization and Other Information",
                  options: { multi: true },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/implants",
                  label: "Implants",
                  options: { format: "enum" },
                },
                {
                  type: "Control",
                  scope: "#/properties/implantsLocalization",
                  label: "Implants' Localization and Other Information",
                  options: { multi: true },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/otherProsthetics",
                  label: "Other Prosthetics",
                  options: { format: "enum" },
                },
                {
                  type: "Control",
                  scope: "#/properties/prostheticsLocalization",
                  label:
                    "Other Prosthetics' Localization and Other Information",
                  options: { multi: true },
                },
              ],
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "Control",
              scope: "#/properties/priority",
              label: "Priority",
              options: { multi: true },
            },
            {
              type: "Control",
              scope: "#/properties/fixedText",
              label: "Fixed Text",
              options: { multi: true },
            },
          ],
        },
      ],
    },
  },
  {
    id: "template19key",
    schema: {
      type: "object",
      serviceType: "Kontrola retence",
      title: "Check Up Retention",
      description: "",
      properties: {
        currentRetainer: {
          type: "integer",
          minimum: 1,
          medicalDocumentation: "yes",
        },
        recommendationHowLongDaily: {
          type: "string",
          enum: ["twentyTwoHoursDaily", "afternoonNight", "night", "other"],
          medicalDocumentation: "yes",
        },
        infoIfRecommendationOther: {
          type: "string",
          medicalDocumentation: "yes",
        },
        retainerAlignement: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        localizationOfRetainerMisalignement: {
          type: "string",
          medicalDocumentation: "yes",
        },
        intraoralPhotosTaken: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        reasonIfNoIntraoralPhotos: {
          type: "string",
          medicalDocumentation: "yes",
        },
        scanTaken: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        reasonIfNoScan: {
          type: "string",
          medicalDocumentation: "yes",
        },
        notes: {
          type: "string",
          medicalDocumentation: "yes",
        },
        fixedText: {
          type: "string",
          medicalDocumentation: "yes",
        },
      },
      required: [
        "currentRetainer",
        "recommendationHowLongDaily",
        "infoIfRecommendationOther",
        "retainerAlignement",
        "localizationOfRetainerMisalignement",
        "intraoralPhotosTaken",
        "reasonIfNoIntraoralPhotos",
        "scanTaken",
        "reasonIfNoScan",
        "notes",
      ],
    },
    uischema: {
      type: "VerticalLayout",
      elements: [
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/currentRetainer",
                  label: "Current Retainer",
                  options: { format: "number" },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/recommendationHowLongDaily",
                  label: "Recommendation How Long Daily",
                  options: { format: "enum" },
                },
                {
                  type: "Control",
                  scope: "#/properties/infoIfRecommendationOther",
                  label: "Info if recommendation 'other'",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/recommendationHowLongDaily",
                      schema: { const: "other" },
                    },
                  },
                },
              ],
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/retainerAlignement",
                  label: "Retainer Alignment",
                  options: { format: "radio" },
                },
                {
                  type: "Control",
                  scope: "#/properties/localizationOfRetainerMisalignement",
                  label: "Localization of Retainer Misalignment",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/retainerAlignement",
                      schema: { const: "No" },
                    },
                  },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/intraoralPhotosTaken",
                  label: "Intraoral Photos Taken",
                  options: { format: "radio" },
                },
                {
                  type: "Control",
                  scope: "#/properties/reasonIfNoIntraoralPhotos",
                  label: "Reason (if no intraoral photos)",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/intraoralPhotosTaken",
                      schema: { const: "No" },
                    },
                  },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/scanTaken",
                  label: "Scan Taken",
                  options: { format: "radio" },
                },
                {
                  type: "Control",
                  scope: "#/properties/reasonIfNoScan",
                  label: "Reason (if no scan)",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/scanTaken",
                      schema: { const: "No" },
                    },
                  },
                },
              ],
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "Control",
              scope: "#/properties/notes",
              label: "Notes",
              options: { multi: true },
            },
            {
              type: "Control",
              scope: "#/properties/fixedText",
              label: "Fixed Text",
              options: { multi: true },
            },
          ],
        },
      ],
    },
  },
  {
    id: "template10key",
    schema: {
      type: "object",
      serviceType: "Kontrola",
      title: "Check Up",
      description: "",
      properties: {
        currentRetainer: {
          type: "integer",
          minimum: 1,
          medicalDocumentation: "yes",
        },
        changesRetainerAfter: {
          type: "integer",
          minimum: 1,
          medicalDocumentation: "yes",
        },
        howLongDaily: {
          type: "integer",
          minimum: 1,
          medicalDocumentation: "yes",
        },
        retainerAlignement: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        localizationOfRetainerMisalignement: {
          type: "string",
          medicalDocumentation: "yes",
        },
        handoverUpTo: {
          type: "integer",
          medicalDocumentation: "yes",
        },
        changeRetainerAfter: {
          type: "integer",
          minimum: 1,
          medicalDocumentation: "yes",
        },
        attachmentsAndPinsOk: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        reasonIfAttachmentsOrPinsNotOk: {
          type: "string",
          medicalDocumentation: "yes",
        },
        rubberBands: {
          type: "string",
          enum: ["yes", "incomplete", "change", "notPlanned"],
          medicalDocumentation: "yes",
        },
        infoIncompleteRubberBands: {
          type: "string",
          medicalDocumentation: "yes",
        },
        infoChangeRubberBands: {
          type: "string",
          medicalDocumentation: "yes",
        },
        notes: {
          type: "string",
          medicalDocumentation: "yes",
        },
        fixedText: {
          type: "string",
          medicalDocumentation: "yes",
        },
      },
      required: [
        "currentRetainer",
        "changesRetainerAfter",
        "howLongDaily",
        "retainerAlignement",
        "localizationOfRetainerMisalignement",
        "changeRetainerAfter",
        "handoverUpTo",
        "attachmentsAndPinsOk",
        "reasonIfAttachmentsOrPinsNotOk",
        "rubberBands",
        "infoIncompleteRubberBands",
        "infoChangeRubberBands",
        "notes",
      ],
    },
    uischema: {
      type: "VerticalLayout",
      elements: [
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/currentRetainer",
                  label: "Current Retainer",
                  options: { format: "number" },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/changesRetainerAfter",
                  label: "Changes Retainer After (days)",
                  options: { format: "number" },
                },
              ],
            },
            {
              type: "Control",
              scope: "#/properties/howLongDaily",
              label: "How Long Daily (hours)",
              options: { format: "number" },
            },
            {
              type: "Control",
              scope: "#/properties/handoverUpTo",
              label: "Number up to ",
              options: { multi: false },
            },
            {
              type: "Control",
              scope: "#/properties/changeRetainerAfter",
              label: "Change Retainer After (days)",
              options: { format: "number" },
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/retainerAlignement",
                  label: "Retainer Alignment",
                  options: { format: "radio" },
                },
                {
                  type: "Control",
                  scope: "#/properties/localizationOfRetainerMisalignement",
                  label: "Localization of Retainer Misalignment",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/retainerAlignement",
                      schema: { const: "No" },
                    },
                  },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/attachmentsAndPinsOk",
                  label: "Attachments and Pins OK",
                  options: { format: "radio" },
                },
                {
                  type: "Control",
                  scope: "#/properties/reasonIfAttachmentsOrPinsNotOk",
                  label: "Reason (if attachments or pins not ok)",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/attachmentsAndPinsOk",
                      schema: { const: "No" },
                    },
                  },
                },
              ],
            },
            {
              type: "Label",
              text: "",
            },
            {
              type: "Label",
              text: "",
            },
            {
              type: "Label",
              text: "",
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/rubberBands",
                  label: "RubberBands",
                  options: { format: "enum" },
                },
                {
                  type: "Control",
                  scope: "#/properties/infoIncompleteRubberBands",
                  label: "Info (incomplete)",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/rubberBands",
                      schema: { const: "incomplete" },
                    },
                  },
                },
                {
                  type: "Control",
                  scope: "#/properties/infoChangeRubberBands",
                  label: "Info (change)",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/rubberBands",
                      schema: { const: "change" },
                    },
                  },
                },
              ],
            },
            {
              type: "Control",
              scope: "#/properties/notes",
              label: "Notes",
              options: { multi: true },
            },
            {
              type: "Control",
              scope: "#/properties/fixedText",
              label: "Fixed Text",
              options: { multi: true },
            },
          ],
        },
      ],
    },
  },
  {
    id: "template11key",
    schema: {
      type: "object",
      serviceType: "Kontrola IPR",
      title: "Check Up IPR",
      description: "",
      properties: {
        currentRetainer: {
          type: "integer",
          minimum: 1,
          medicalDocumentation: "yes",
        },
        changesRetainerAfter: {
          type: "integer",
          minimum: 1,
          medicalDocumentation: "yes",
        },
        howLongDaily: {
          type: "integer",
          minimum: 1,
          medicalDocumentation: "yes",
        },
        retainerAlignement: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        localizationOfRetainerMisalignement: {
          type: "string",
          medicalDocumentation: "yes",
        },
        handoverUpTo: {
          type: "integer",
          medicalDocumentation: "yes",
        },
        changeRetainerAfter: {
          type: "integer",
          minimum: 1,
          medicalDocumentation: "yes",
        },
        attachmentsAndPinsOk: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        reasonIfAttachmentsOrPinsNotOk: {
          type: "string",
          medicalDocumentation: "yes",
        },
        ipr: {
          type: "string",
          enum: ["yes", "incomplete", "notPlanned"],
          medicalDocumentation: "yes",
        },
        infoIncompleteIpr: {
          type: "string",
          medicalDocumentation: "yes",
        },
        rubberBands: {
          type: "string",
          enum: ["yes", "incomplete", "change", "notPlanned"],
          medicalDocumentation: "yes",
        },
        infoIncompleteRubberBands: {
          type: "string",
          medicalDocumentation: "yes",
        },
        infoChangeRubberBands: {
          type: "string",
          medicalDocumentation: "yes",
        },
        cpInstructionsFollowed: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        reasonIfCpInstructionsNotFollowed: {
          type: "string",
          medicalDocumentation: "yes",
        },
        notes: {
          type: "string",
          medicalDocumentation: "yes",
        },
        fixedText: {
          type: "string",
          medicalDocumentation: "yes",
        },
      },
      required: [
        "currentRetainer",
        "changesRetainerAfter",
        "howLongDaily",
        "retainerAlignement",
        "localizationOfRetainerMisalignement",
        "handoverUpTo",
        "changeRetainerAfter",
        "attachmentsAndPinsOk",
        "reasonIfAttachmentsOrPinsNotOk",
        "ipr",
        "infoIncompleteIpr",
        "rubberBands",
        "infoIncompleteRubberBands",
        "infoChangeRubberBands",
        "cpInstructionsFollowed",
        "reasonIfCpInstructionsNotFollowed",
        "notes",
      ],
    },
    uischema: {
      type: "VerticalLayout",
      elements: [
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/currentRetainer",
                  label: "Current Retainer",
                  options: { format: "number" },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/changesRetainerAfter",
                  label: "Changes Retainer After (days)",
                  options: { format: "number" },
                },
              ],
            },
            {
              type: "Control",
              scope: "#/properties/howLongDaily",
              label: "How Long Daily (hours)",
              options: { format: "number" },
            },
            {
              type: "Control",
              scope: "#/properties/handoverUpTo",
              label: "Number up to ",
              options: { multi: false },
            },
            {
              type: "Control",
              scope: "#/properties/changeRetainerAfter",
              label: "Change Retainer After (days)",
              options: { format: "number" },
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/retainerAlignement",
                  label: "Retainer Alignment",
                  options: { format: "radio" },
                },
                {
                  type: "Control",
                  scope: "#/properties/localizationOfRetainerMisalignement",
                  label: "Localization of Retainer Misalignment",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/retainerAlignement",
                      schema: { const: "No" },
                    },
                  },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/attachmentsAndPinsOk",
                  label: "Attachments and Pins OK",
                  options: { format: "radio" },
                },
                {
                  type: "Control",
                  scope: "#/properties/reasonIfAttachmentsOrPinsNotOk",
                  label: "Reason (if attachments or pins not ok)",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/attachmentsAndPinsOk",
                      schema: { const: "No" },
                    },
                  },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/cpInstructionsFollowed",
                  label: "CP Instructions Followed",
                  options: { format: "radio" },
                },
                {
                  type: "Control",
                  scope: "#/properties/reasonIfCpInstructionsNotFollowed",
                  label: "Reason if CP Instructions not Followed",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/cpInstructionsFollowed",
                      schema: { const: "No" },
                    },
                  },
                },
              ],
            },
            {
              type: "Label",
              text: "",
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/ipr",
                  label: "IPR",
                  options: { format: "enum" },
                },
                {
                  type: "Control",
                  scope: "#/properties/infoIncompleteIpr",
                  label: "Info (incomplete)",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/ipr",
                      schema: { const: "incomplete" },
                    },
                  },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/rubberBands",
                  label: "RubberBands",
                  options: { format: "enum" },
                },
                {
                  type: "Control",
                  scope: "#/properties/infoIncompleteRubberBands",
                  label: "Info (incomplete)",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/rubberBands",
                      schema: { const: "incomplete" },
                    },
                  },
                },
                {
                  type: "Control",
                  scope: "#/properties/infoChangeRubberBands",
                  label: "Info (change)",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/rubberBands",
                      schema: { const: "change" },
                    },
                  },
                },
              ],
            },
            {
              type: "Control",
              scope: "#/properties/notes",
              label: "Notes",
              options: { multi: true },
            },
            {
              type: "Control",
              scope: "#/properties/fixedText",
              label: "Fixed Text",
              options: { multi: true },
            },
            {
              type: "Label",
              text: "",
            },
          ],
        },
      ],
    },
  },
  {
    id: "template12key",
    schema: {
      type: "object",
      serviceType: "Kontrola Virtual Care",
      title: "Check Up Virtual Care",
      description: "",
      properties: {
        currentRetainer: {
          type: "integer",
          minimum: 1,
          medicalDocumentation: "yes",
        },
        changesRetainerAfter: {
          type: "integer",
          minimum: 1,
          medicalDocumentation: "yes",
        },
        howLongDaily: {
          type: "integer",
          minimum: 1,
          medicalDocumentation: "yes",
        },
        retainerAlignement: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        localizationOfRetainerMisalignement: {
          type: "string",
          medicalDocumentation: "yes",
        },
        handoverUpTo: {
          type: "integer",
          medicalDocumentation: "yes",
        },
        changeRetainerAfter: {
          type: "integer",
          minimum: 1,
          medicalDocumentation: "yes",
        },
        attachmentsAndPinsOk: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        reasonIfAttachmentsOrPinsNotOk: {
          type: "string",
          medicalDocumentation: "yes",
        },
        ipr: {
          type: "string",
          enum: ["yes", "incomplete", "notPlanned"],
          medicalDocumentation: "yes",
        },
        infoIncompleteIpr: {
          type: "string",
          medicalDocumentation: "yes",
        },
        rubberBands: {
          type: "string",
          enum: ["yes", "incomplete", "change", "notPlanned"],
          medicalDocumentation: "yes",
        },
        infoIncompleteRubberBands: {
          type: "string",
          medicalDocumentation: "yes",
        },
        infoChangeRubberBands: {
          type: "string",
          medicalDocumentation: "yes",
        },
        cpInstructionsFollowed: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        reasonIfCpInstructionsNotFollowed: {
          type: "string",
          medicalDocumentation: "yes",
        },
        notes: {
          type: "string",
          medicalDocumentation: "yes",
        },
        fixedText: {
          type: "string",
          medicalDocumentation: "yes",
        },
      },
      required: [
        "currentRetainer",
        "changesRetainerAfter",
        "howLongDaily",
        "retainerAlignement",
        "localizationOfRetainerMisalignement",
        "handoverUpTo",
        "changeRetainerAfter",
        "attachmentsAndPinsOk",
        "reasonIfAttachmentsOrPinsNotOk",
        "ipr",
        "infoIncompleteIpr",
        "rubberBands",
        "infoIncompleteRubberBands",
        "infoChangeRubberBands",
        "cpInstructionsFollowed",
        "reasonIfCpInstructionsNotFollowed",
        "notes",
      ],
    },
    uischema: {
      type: "VerticalLayout",
      elements: [
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/currentRetainer",
                  label: "Current Retainer",
                  options: { format: "number" },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/changesRetainerAfter",
                  label: "Changes Retainer After (days)",
                  options: { format: "number" },
                },
              ],
            },
            {
              type: "Control",
              scope: "#/properties/howLongDaily",
              label: "How Long Daily (hours)",
              options: { format: "number" },
            },
            {
              type: "Control",
              scope: "#/properties/handoverUpTo",
              label: "Number up to ",
              options: { multi: false },
            },
            {
              type: "Control",
              scope: "#/properties/changeRetainerAfter",
              label: "Change Retainer After (days)",
              options: { format: "number" },
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/retainerAlignement",
                  label: "Retainer Alignment",
                  options: { format: "radio" },
                },
                {
                  type: "Control",
                  scope: "#/properties/localizationOfRetainerMisalignement",
                  label: "Localization of Retainer Misalignment",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/retainerAlignement",
                      schema: { const: "No" },
                    },
                  },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/attachmentsAndPinsOk",
                  label: "Attachments and Pins OK",
                  options: { format: "radio" },
                },
                {
                  type: "Control",
                  scope: "#/properties/reasonIfAttachmentsOrPinsNotOk",
                  label: "Reason (if attachments or pins not ok)",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/attachmentsAndPinsOk",
                      schema: { const: "No" },
                    },
                  },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/cpInstructionsFollowed",
                  label: "CP Instructions Followed",
                  options: { format: "radio" },
                },
                {
                  type: "Control",
                  scope: "#/properties/reasonIfCpInstructionsNotFollowed",
                  label: "Reason if CP Instructions not Followed",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/cpInstructionsFollowed",
                      schema: { const: "No" },
                    },
                  },
                },
              ],
            },
            {
              type: "Label",
              text: "",
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/ipr",
                  label: "IPR",
                  options: { format: "enum" },
                },
                {
                  type: "Control",
                  scope: "#/properties/infoIncompleteIpr",
                  label: "Info (incomplete)",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/ipr",
                      schema: { const: "incomplete" },
                    },
                  },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/rubberBands",
                  label: "RubberBands",
                  options: { format: "enum" },
                },
                {
                  type: "Control",
                  scope: "#/properties/infoIncompleteRubberBands",
                  label: "Info (incomplete)",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/rubberBands",
                      schema: { const: "incomplete" },
                    },
                  },
                },
                {
                  type: "Control",
                  scope: "#/properties/infoChangeRubberBands",
                  label: "Info (change)",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/rubberBands",
                      schema: { const: "change" },
                    },
                  },
                },
              ],
            },
            {
              type: "Control",
              scope: "#/properties/notes",
              label: "Notes",
              options: { multi: true },
            },
            {
              type: "Control",
              scope: "#/properties/fixedText",
              label: "Fixed Text",
              options: { multi: true },
            },
            {
              type: "Label",
              text: "",
            },
          ],
        },
      ],
    },
  },
  {
    id: "template13key",
    schema: {
      type: "object",
      serviceType: "Oprava",
      title: "Repair",
      description: "",
      properties: {
        currentRetainer: {
          type: "integer",
          minimum: 1,
          medicalDocumentation: "yes",
        },
        changesRetainerAfter: {
          type: "integer",
          minimum: 1,
          medicalDocumentation: "yes",
        },
        howLongDaily: {
          type: "integer",
          minimum: 1,
          medicalDocumentation: "yes",
        },
        retainerAlignement: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        localizationOfRetainerMisalignement: {
          type: "string",
          medicalDocumentation: "yes",
        },
        handoverUpTo: {
          type: "integer",
          medicalDocumentation: "yes",
        },
        changeRetainerAfter: {
          type: "integer",
          minimum: 1,
          medicalDocumentation: "yes",
        },
        attachmentsAndPinsOk: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        reasonIfAttachmentsOrPinsNotOk: {
          type: "string",
          medicalDocumentation: "yes",
        },
        rubberBands: {
          type: "string",
          enum: ["yes", "incomplete", "change", "notPlanned"],
          medicalDocumentation: "yes",
        },
        infoIncompleteRubberBands: {
          type: "string",
          medicalDocumentation: "yes",
        },
        infoChangeRubberBands: {
          type: "string",
          medicalDocumentation: "yes",
        },
        notes: {
          type: "string",
          medicalDocumentation: "yes",
        },
        fixedText: {
          type: "string",
          medicalDocumentation: "yes",
        },
      },
      required: [
        "currentRetainer",
        "changesRetainerAfter",
        "howLongDaily",
        "retainerAlignement",
        "localizationOfRetainerMisalignement",
        "handoverUpTo",
        "changeRetainerAfter",
        "attachmentsAndPinsOk",
        "reasonIfAttachmentsOrPinsNotOk",
        "rubberBands",
        "infoIncompleteRubberBands",
        "infoChangeRubberBands",
        "notes",
      ],
    },
    uischema: {
      type: "VerticalLayout",
      elements: [
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/currentRetainer",
                  label: "Current Retainer",
                  options: { format: "number" },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/changesRetainerAfter",
                  label: "Changes Retainer After (days)",
                  options: { format: "number" },
                },
              ],
            },
            {
              type: "Control",
              scope: "#/properties/howLongDaily",
              label: "How Long Daily (hours)",
              options: { format: "number" },
            },
            {
              type: "Control",
              scope: "#/properties/handoverUpTo",
              label: "Number up to ",
              options: { multi: false },
            },
            {
              type: "Control",
              scope: "#/properties/changeRetainerAfter",
              label: "Change Retainer After (days)",
              options: { format: "number" },
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/retainerAlignement",
                  label: "Retainer Crowd-in",
                  options: { format: "radio" },
                },
                {
                  type: "Control",
                  scope: "#/properties/localizationOfRetainerMisalignement",
                  label: "Localization of Retainer Crowd-in",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/retainerAlignement",
                      schema: { const: "No" },
                    },
                  },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/attachmentsAndPinsOk",
                  label: "Attachments and Pins OK",
                  options: { format: "radio" },
                },
                {
                  type: "Control",
                  scope: "#/properties/reasonIfAttachmentsOrPinsNotOk",
                  label: "Reason (if attachments or pins not ok)",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/attachmentsAndPinsOk",
                      schema: { const: "No" },
                    },
                  },
                },
              ],
            },
            {
              type: "Label",
              text: "",
            },
            {
              type: "Label",
              text: "",
            },
            {
              type: "Label",
              text: "",
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/rubberBands",
                  label: "RubberBands",
                  options: { format: "enum" },
                },
                {
                  type: "Control",
                  scope: "#/properties/infoIncompleteRubberBands",
                  label: "Info (incomplete)",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/rubberBands",
                      schema: { const: "incomplete" },
                    },
                  },
                },
                {
                  type: "Control",
                  scope: "#/properties/infoChangeRubberBands",
                  label: "Info (change)",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/rubberBands",
                      schema: { const: "change" },
                    },
                  },
                },
              ],
            },
            {
              type: "Control",
              scope: "#/properties/notes",
              label: "Notes",
              options: { multi: true },
            },
            {
              type: "Control",
              scope: "#/properties/fixedText",
              label: "Fixed Text",
              options: { multi: true },
            },
            {
              type: "Label",
              text: "",
            },
          ],
        },
      ],
    },
  },
  {
    id: "template7key",
    schema: {
      type: "object",
      serviceType: "Lepení",
      title: "Bonding",
      description: "",
      properties: {
        currentRetainer: {
          type: "integer",
          minimum: 1,
          medicalDocumentation: "yes",
        },
        attachmentsBonded: {
          type: "string",
          enum: ["yes", "incomplete"],
          medicalDocumentation: "yes",
        },
        infoIncompleteAttachmentsBonded: {
          type: "string",
          medicalDocumentation: "yes",
        },
        ipr: {
          type: "string",
          enum: ["yes", "incomplete", "notPlanned"],
          medicalDocumentation: "yes",
        },
        infoIncompleteIpr: {
          type: "string",
          medicalDocumentation: "yes",
        },
        rubberBands: {
          type: "string",
          enum: ["yes", "incomplete", "change", "notPlanned"],
          medicalDocumentation: "yes",
        },
        infoIncompleteRubberBands: {
          type: "string",
          medicalDocumentation: "yes",
        },
        infoChangeRubberBands: {
          type: "string",
          medicalDocumentation: "yes",
        },
        retainerAlignement: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        handoverUpTo: {
          type: "integer",
          medicalDocumentation: "yes",
        },
        changeRetainerAfter: {
          type: "integer",
          minimum: 1,
          medicalDocumentation: "yes",
        },
        virtualCare: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        lenseSize: {
          type: "string",
          enum: ["S", "M"],
          medicalDocumentation: "yes",
        },
        emailVirtualCare: {
          type: "string",
          medicalDocumentation: "yes",
        },
        notes: {
          type: "string",
          medicalDocumentation: "yes",
        },
        fixedText: {
          type: "string",
          medicalDocumentation: "yes",
        },
      },
      required: [
        "currentRetainer",
        "attachmentsBonded",
        "infoIncompleteAttachmentsBonded",
        "ipr",
        "infoIncompleteIpr",
        "info",
        "rubberBands",
        "infoIncompleteRubberBands",
        "infoChangeRubberBands",
        "retainerAlignement",
        "handoverUpTo",
        "changeRetainerAfter",
        "virtualCare",
        "lenseSize",
        "emailVirtualCare",
        "notes",
      ],
    },
    uischema: {
      type: "VerticalLayout",
      elements: [
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "HorizontalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/currentRetainer",
                  label: "Current Retainer",
                  options: { format: "number" },
                },
                {
                  type: "Control",
                  scope: "#/properties/handoverUpTo",
                  label: "Number up to ",
                  options: { multi: false },
                },
                {
                  type: "Control",
                  scope: "#/properties/changeRetainerAfter",
                  label: "Change Retainer After (days)",
                  options: { format: "number" },
                },
              ],
            },
            {
              type: "HorizontalLayout",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      scope: "#/properties/attachmentsBonded",
                      label: "Attachments Bonded",
                      options: { format: "enum" },
                    },
                    {
                      type: "Control",
                      scope: "#/properties/infoIncompleteAttachmentsBonded",
                      label: "Info (incomplete)",
                      options: { multi: true },
                      rule: {
                        effect: "SHOW",
                        condition: {
                          scope: "#/properties/attachmentsBonded",
                          schema: { const: "incomplete" },
                        },
                      },
                    },
                  ],
                },
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      scope: "#/properties/ipr",
                      label: "IPR",
                      options: { format: "enum" },
                    },
                    {
                      type: "Control",
                      scope: "#/properties/infoIncompleteIpr",
                      label: "Info (incomplete)",
                      options: { multi: true },
                      rule: {
                        effect: "SHOW",
                        condition: {
                          scope: "#/properties/ipr",
                          schema: { const: "incomplete" },
                        },
                      },
                    },
                  ],
                },
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      scope: "#/properties/rubberBands",
                      label: "RubberBands",
                      options: { format: "enum" },
                    },
                    {
                      type: "Control",
                      scope: "#/properties/infoIncompleteRubberBands",
                      label: "Info (incomplete)",
                      options: { multi: true },
                      rule: {
                        effect: "SHOW",
                        condition: {
                          scope: "#/properties/rubberBands",
                          schema: { const: "incomplete" },
                        },
                      },
                    },
                    {
                      type: "Control",
                      scope: "#/properties/infoChangeRubberBands",
                      label: "Info (change)",
                      options: { multi: true },
                      rule: {
                        effect: "SHOW",
                        condition: {
                          scope: "#/properties/rubberBands",
                          schema: { const: "change" },
                        },
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "Control",
              scope: "#/properties/retainerAlignement",
              label: "Retainer Crowd-in",
              options: { format: "radio" },
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/virtualCare",
                  label: "Virtual Care",
                  options: { format: "radio" },
                },
                {
                  type: "HorizontalLayout",
                  elements: [
                    {
                      type: "Control",
                      scope: "#/properties/emailVirtualCare",
                      label: "Email Virtual Care",
                      options: { format: "email" },
                      rule: {
                        effect: "SHOW",
                        condition: {
                          scope: "#/properties/virtualCare",
                          schema: { const: "Yes" },
                        },
                      },
                    },
                    {
                      type: "Control",
                      scope: "#/properties/lenseSize",
                      label: "Lense Size",
                      options: { format: "enum" },
                      rule: {
                        effect: "SHOW",
                        condition: {
                          scope: "#/properties/virtualCare",
                          schema: { const: "Yes" },
                        },
                      },
                    },
                    {
                      type: "Label",
                      text: "",
                    },
                    {
                      type: "Label",
                      text: "",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "Control",
              scope: "#/properties/notes",
              label: "Notes",
              options: { multi: true },
            },
            {
              type: "Control",
              scope: "#/properties/fixedText",
              label: "Fixed Text",
              options: { multi: true },
            },
            {
              type: "Label",
              text: "",
            },
            {
              type: "Label",
              text: "",
            },
          ],
        },
      ],
    },
  },
  {
    id: "template9key",
    schema: {
      type: "object",
      serviceType: "Scan na refinement",
      title: "Scan for refinement",
      description: "",
      properties: {
        currentRetainer: {
          type: "integer",
          minimum: 1,
          medicalDocumentation: "yes",
        },
        changesRetainerAfter: {
          type: "integer",
          minimum: 1,
          medicalDocumentation: "yes",
        },
        howLongDaily: {
          type: "integer",
          minimum: 1,
          medicalDocumentation: "yes",
        },
        initiatedByClient: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        clientWish: {
          type: "string",
          medicalDocumentation: "yes",
        },
        reasonForRefinement: {
          type: "string",
          enum: [
            "retainersDontFit",
            "changeOfTreatmentPlan",
            "smallRefinement",
            "other",
          ],
          medicalDocumentation: "yes",
        },
        problemDescription: {
          type: "string",
          medicalDocumentation: "yes",
        },
        medicalInstructions: {
          type: "string",
          medicalDocumentation: "yes",
        },
        extraoralPhotosTaken: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        reasonIfNoExtraoralPhotos: {
          type: "string",
          medicalDocumentation: "yes",
        },
        intraoralPhotosTaken: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        reasonIfNoIntraoralPhotos: {
          type: "string",
          medicalDocumentation: "yes",
        },
        scanTaken: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        reasonIfNoScan: {
          type: "string",
          medicalDocumentation: "yes",
        },
        fixedText: {
          type: "string",
          medicalDocumentation: "yes",
        },
      },
      required: [
        "currentRetainer",
        "changesRetainerAfter",
        "howLongDaily",
        "initiatedByClient",
        "clientWish",
        "reasonForRefinement",
        "problemDescription",
        "medicalInstructions",
        "extraoralPhotosTaken",
        "reasonIfNoExtraoralPhotos",
        "intraoralPhotosTaken",
        "reasonIfNoIntraoralPhotos",
        "scanTaken",
        "reasonIfNoScan",
      ],
    },
    uischema: {
      type: "VerticalLayout",
      elements: [
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/currentRetainer",
                  label: "Current Retainer",
                  options: { format: "number" },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/changesRetainerAfter",
                  label: "Changes Retainer After (days)",
                  options: { format: "number" },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/howLongDaily",
                  label: "How Long Daily (hours)",
                  options: { format: "number" },
                },
              ],
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "Control",
              scope: "#/properties/initiatedByClient",
              label: "Initiated by Client",
              options: { format: "radio" },
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "Control",
              scope: "#/properties/clientWish",
              label: "Client Wish",
              options: { multi: true },
            },
            {
              type: "Control",
              scope: "#/properties/reasonForRefinement",
              label: "Reason for Refinement",
              options: { format: "enum" },
            },
            {
              type: "Control",
              scope: "#/properties/problemDescription",
              label: "Problem Description",
              options: { multi: true },
            },
            {
              type: "Control",
              scope: "#/properties/medicalInstructions",
              label: "Medical Instructions",
              options: { multi: true },
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/extraoralPhotosTaken",
                  label: "Extraoral Photos Taken",
                  options: { format: "radio" },
                },
                {
                  type: "Control",
                  scope: "#/properties/reasonIfNoExtraoralPhotos",
                  label: "Reason (if no extraoral photos)",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/extraoralPhotosTaken",
                      schema: { const: "No" },
                    },
                  },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/intraoralPhotosTaken",
                  label: "Intraoral Photos Taken",
                  options: { format: "radio" },
                },
                {
                  type: "Control",
                  scope: "#/properties/reasonIfNoIntraoralPhotos",
                  label: "Reason (if no intraoral photos)",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/intraoralPhotosTaken",
                      schema: { const: "No" },
                    },
                  },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/scanTaken",
                  label: "Scan Taken",
                  options: { format: "radio" },
                },
                {
                  type: "Control",
                  scope: "#/properties/reasonIfNoScan",
                  label: "Reason (if no scan)",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/scanTaken",
                      schema: { const: "No" },
                    },
                  },
                },
              ],
            },
            {
              type: "Label",
              text: "",
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "Control",
              scope: "#/properties/fixedText",
              label: "Fixed Text",
              options: { multi: true },
            },
            {
              type: "Label",
              text: "",
            },
            {
              type: "Label",
              text: "",
            },
            {
              type: "Label",
              text: "",
            },
          ],
        },
      ],
    },
  },
  {
    id: "template8key",
    schema: {
      type: "object",
      serviceType: "Scan na warranty",
      title: "Scan for warranty",
      description: "",
      properties: {
        currentRetainer: {
          type: "integer",
          minimum: 1,
          medicalDocumentation: "yes",
        },
        initiatedByClient: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        clientWish: {
          type: "string",
          medicalDocumentation: "yes",
        },
        reasonForWarrantyFit: {
          type: "string",
          enum: [
            "retainersDontFit",
            "unsuitableTreatmentPlan",
            "manufacturingDefect",
            "other",
          ],
          medicalDocumentation: "yes",
        },
        problemDescription: {
          type: "string",
          medicalDocumentation: "yes",
        },
        medicalInstructions: {
          type: "string",
          medicalDocumentation: "yes",
        },
        extraoralPhotosTaken: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        reasonIfNoExtraoralPhotos: {
          type: "string",
          medicalDocumentation: "yes",
        },
        intraoralPhotosTaken: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        reasonIfNoIntraoralPhotos: {
          type: "string",
          medicalDocumentation: "yes",
        },
        scanTaken: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        reasonIfNoScan: {
          type: "string",
          medicalDocumentation: "yes",
        },
        fixedText: {
          type: "string",
          medicalDocumentation: "yes",
        },
      },
      required: [
        "currentRetainer",
        "initiatedByClient",
        "clientWish",
        "reasonForWarrantyFit",
        "problemDescription",
        "medicalInstructions",
        "extraoralPhotosTaken",
        "reasonIfNoExtraoralPhotos",
        "intraoralPhotosTaken",
        "reasonIfNoIntraoralPhotos",
        "scanTaken",
        "reasonIfNoScan",
      ],
    },
    uischema: {
      type: "VerticalLayout",
      elements: [
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/currentRetainer",
                  label: "Current Retainer",
                  options: { format: "number" },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/initiatedByClient",
                  label: "Initiated by Client",
                  options: { format: "radio" },
                },
              ],
            },
            {
              type: "Control",
              scope: "#/properties/clientWish",
              label: "Client Wish",
              options: { multi: true },
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/reasonForWarrantyFit",
                  label: "Reason for Warranty Fit",
                  options: { format: "enum" },
                },
              ],
            },
            {
              type: "Control",
              scope: "#/properties/problemDescription",
              label: "Problem Description",
              options: { multi: true },
            },
            {
              type: "Control",
              scope: "#/properties/medicalInstructions",
              label: "Medical Instructions",
              options: { multi: true },
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/extraoralPhotosTaken",
                  label: "Extraoral Photos Taken",
                  options: { format: "radio" },
                },
                {
                  type: "Control",
                  scope: "#/properties/reasonIfNoExtraoralPhotos",
                  label: "Reason (if no extraoral photos)",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/extraoralPhotosTaken",
                      schema: { const: "No" },
                    },
                  },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/intraoralPhotosTaken",
                  label: "Intraoral Photos Taken",
                  options: { format: "radio" },
                },
                {
                  type: "Control",
                  scope: "#/properties/reasonIfNoIntraoralPhotos",
                  label: "Reason (if no intraoral photos)",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/intraoralPhotosTaken",
                      schema: { const: "No" },
                    },
                  },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/scanTaken",
                  label: "Scan Taken",
                  options: { format: "radio" },
                },
                {
                  type: "Control",
                  scope: "#/properties/reasonIfNoScan",
                  label: "Reason (if no scan)",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/scanTaken",
                      schema: { const: "No" },
                    },
                  },
                },
              ],
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "Control",
              scope: "#/properties/fixedText",
              label: "Fixed Text",
              options: { multi: true },
            },
            {
              type: "Label",
              text: "",
            },
            {
              type: "Label",
              text: "",
            },
          ],
        },
      ],
    },
  },
  {
    id: "template14key",
    schema: {
      type: "object",
      serviceType: "Výměna elastiky",
      title: "Change of elastics",
      description: "",
      properties: {
        typeOfElastic: {
          type: "string",
          enum: ["ligature", "chain"],
          medicalDocumentation: "yes",
        },
        localization: {
          type: "string",
          medicalDocumentation: "yes",
        },
        notes: {
          type: "string",
          medicalDocumentation: "yes",
        },
        fixedText: {
          type: "string",
          medicalDocumentation: "yes",
        },
      },
      required: ["typeOfElastic", "localization", "notes"],
    },
    uischema: {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/typeOfElastic",
          label: "Typ gumiček",
          options: { format: "enum" },
        },
        {
          type: "Control",
          scope: "#/properties/localization",
          label: "Localization",
          options: { multi: true },
        },
        {
          type: "Control",
          scope: "#/properties/notes",
          label: "Notes",
          options: { multi: true },
        },
        {
          type: "Control",
          scope: "#/properties/fixedText",
          label: "Fixed Text",
          options: { multi: true },
        },
      ],
    },
  },
  {
    id: "template17key",
    schema: {
      type: "object",
      serviceType: "Debonding",
      title: "Debonding",
      description: "",
      properties: {
        attachmentsTakenOff: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        reasonIfAttachmentsNotTakenOff: {
          type: "string",
          medicalDocumentation: "yes",
        },
        pinsTakenOff: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        reasonIfPinsNotTakenOff: {
          type: "string",
          medicalDocumentation: "yes",
        },
        handoverOfRetention: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        reasonIfNoHandoverOfRetention: {
          type: "string",
          medicalDocumentation: "yes",
        },
        opgTaken: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        reasonIfNoOpg: {
          type: "string",
          medicalDocumentation: "yes",
        },
        teleTaken: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        reasonIfNoTele: {
          type: "string",
          medicalDocumentation: "yes",
        },
        scanTaken: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        reasonIfNoScan: {
          type: "string",
          medicalDocumentation: "yes",
        },
        notes: {
          type: "string",
          medicalDocumentation: "yes",
        },
        fixedText: {
          type: "string",
          medicalDocumentation: "yes",
        },
      },
      required: [
        "attachmentsTakenOff",
        "reasonIfAttachmentsNotTakenOff",
        "pinsTakenOff",
        "reasonIfPinsNotTakenOff",
        "handoverOfRetention",
        "reasonIfNoHandoverOfRetention",
        "opgTaken",
        "reasonIfNoOpg",
        "teleTaken",
        "reasonIfNoTele",
        "scanTaken",
        "reasonIfNoScan",
        "notes",
      ],
    },
    uischema: {
      type: "VerticalLayout",
      elements: [
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/attachmentsTakenOff",
                  label: "Attachments taken off",
                  options: { format: "radio" },
                },
                {
                  type: "Control",
                  scope: "#/properties/reasonIfAttachmentsNotTakenOff",
                  label: "Reason (if attachments not taken off)",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/attachmentsTakenOff",
                      schema: { const: "No" },
                    },
                  },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/pinsTakenOff",
                  label: "Pins taken off",
                  options: { format: "radio" },
                },
                {
                  type: "Control",
                  scope: "#/properties/reasonIfPinsNotTakenOff",
                  label: "Reason (if pins not taken off)",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/pinsTakenOff",
                      schema: { const: "No" },
                    },
                  },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/handoverOfRetention",
                  label: "Retention handed over",
                  options: { format: "radio" },
                },
                {
                  type: "Control",
                  scope: "#/properties/reasonIfNoHandoverOfRetention",
                  label: "Reason (if no handover of retention)",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/handoverOfRetention",
                      schema: { const: "No" },
                    },
                  },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/opgTaken",
                  label: "OPG taken",
                  options: { format: "radio" },
                },
                {
                  type: "Control",
                  scope: "#/properties/reasonIfNoOpg",
                  label: "Reason (if no OPG)",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/opgTaken",
                      schema: { const: "No" },
                    },
                  },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/teleTaken",
                  label: "TELE taken",
                  options: { format: "radio" },
                },
                {
                  type: "Control",
                  scope: "#/properties/reasonIfNoTele",
                  label: "Reason (if no tele)",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/teleTaken",
                      schema: { const: "No" },
                    },
                  },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/scanTaken",
                  label: "Scan Taken",
                  options: { format: "radio" },
                },
                {
                  type: "Control",
                  scope: "#/properties/reasonIfNoScan",
                  label: "Reason (if no scan)",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/scanTaken",
                      schema: { const: "No" },
                    },
                  },
                },
              ],
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "Control",
              scope: "#/properties/notes",
              label: "Notes",
              options: { multi: true },
            },
            {
              type: "Control",
              scope: "#/properties/fixedText",
              label: "Fixed Text",
              options: { multi: true },
            },
          ],
        },
      ],
    },
  },
  {
    id: "template16key",
    schema: {
      type: "object",
      serviceType: "Scan na debonding",
      title: "Scan for Debonding",
      description: "",
      properties: {
        numberOfRetentionSets: {
          type: "string",
          enum: ["0", "1", "3"],
          medicalDocumentation: "yes",
        },
        reasonIfNoRetentionSet: {
          type: "string",
          medicalDocumentation: "yes",
        },
        pinsTakenOff: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        scanTaken: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        reasonIfNoScan: {
          type: "string",
          medicalDocumentation: "yes",
        },
        fixedText: {
          type: "string",
          medicalDocumentation: "yes",
        },
      },
      required: [
        "numberOfRetentionSets",
        "reasonIfNoRetentionSet",
        "pinsTakenOff",
        "scanTaken",
        "reasonIfNoScan",
      ],
    },
    uischema: {
      type: "VerticalLayout",
      elements: [
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "Control",
              scope: "#/properties/numberOfRetentionSets",
              label: "Number of Retention Sets",
              options: { format: "enum" },
            },
            {
              type: "Control",
              scope: "#/properties/reasonIfNoRetentionSet",
              label: "Reason (if no retention sets)",
              options: { multi: true },
              rule: {
                effect: "SHOW",
                condition: {
                  scope: "#/properties/numberOfRetentionSets",
                  schema: { const: "0" },
                },
              },
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/pinsTakenOff",
                  label: "Pins taken off",
                  options: { format: "radio" },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/scanTaken",
                  label: "Scan Taken",
                  options: { format: "radio" },
                },
                {
                  type: "Control",
                  scope: "#/properties/reasonIfNoScan",
                  label: "Reason (if no scan)",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/scanTaken",
                      schema: { const: "No" },
                    },
                  },
                },
              ],
            },
            {
              type: "Label",
              text: "",
            },
            {
              type: "Label",
              text: "",
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "Control",
              scope: "#/properties/fixedText",
              label: "Fixed Text",
              options: { multi: true },
            },
            {
              type: "Label",
              text: "",
            },
          ],
        },
      ],
    },
  },
  {
    id: "template18key",
    schema: {
      type: "object",
      serviceType: "Exit konzultace",
      title: "Exit consultation",
      description: "",
      properties: {
        notes: {
          type: "string",
          medicalDocumentation: "yes",
        },
        fixedText: {
          type: "string",
          medicalDocumentation: "yes",
        },
      },
      required: ["notes"],
    },
    uischema: {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/notes",
          label: "Notes",
          options: { multi: true },
        },
        {
          type: "Control",
          scope: "#/properties/fixedText",
          label: "Fixed Text",
          options: { multi: true },
        },
      ],
    },
  },
  {
    id: "template15key",
    schema: {
      type: "object",
      serviceType: "Kontrola růstu",
      title: "Check of growth",
      description: "",
      properties: {
        notes: {
          type: "string",
          medicalDocumentation: "yes",
        },
        fixedText: {
          type: "string",
          medicalDocumentation: "yes",
        },
      },
      required: ["notes"],
    },
    uischema: {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/notes",
          label: "Notes",
          options: { multi: true },
        },
        {
          type: "Control",
          scope: "#/properties/fixedText",
          label: "Fixed Text",
          options: { multi: true },
        },
      ],
    },
  },
  {
    id: "template21key",
    schema: {
      type: "object",
      serviceType: "Předání",
      title: "Handover",
      description: "",
      properties: {
        handoverRetainer: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        handoverUpTo: {
          type: "integer",
          medicalDocumentation: "yes",
        },
        notes: {
          type: "string",
          medicalDocumentation: "yes",
        },
        fixedText: {
          type: "string",
          medicalDocumentation: "yes",
        },
      },
      required: ["handoverRetainer", "handoverUpTo"],
    },
    uischema: {
      type: "VerticalLayout",
      elements: [
        {
          type: "VerticalLayout",
          elements: [
            {
              type: "Control",
              scope: "#/properties/handoverRetainer",
              label: "Retainer Handover",
              options: { format: "radio" },
            },
            {
              type: "HorizontalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/handoverUpTo",
                  label: "Number up to (if a handover took place)",
                  options: { multi: false },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/handoverRetainer",
                      schema: { const: "Yes" },
                    },
                  },
                },
                {
                  type: "Label",
                  text: "",
                },
                {
                  type: "Label",
                  text: "",
                },
                {
                  type: "Label",
                  text: "",
                },
              ],
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "Control",
              scope: "#/properties/notes",
              label: "Notes",
              options: { multi: true },
            },
            {
              type: "Control",
              scope: "#/properties/fixedText",
              label: "Fixed Text",
              options: { multi: true },
            },
          ],
        },
      ],
    },
  },
  {
    id: "template20key",
    schema: {
      type: "object",
      serviceType: "Jiné",
      title: "Other",
      description: "",
      properties: {
        notes: {
          type: "string",
          medicalDocumentation: "yes",
        },
      },
      required: ["notes"],
    },
    uischema: {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/notes",
          label: "Notes",
          options: { multi: true },
        },
        {
          type: "Label",
          text: "",
        },
        {
          type: "Label",
          text: "",
        },
        {
          type: "Label",
          text: "",
        },
      ],
    },
  },
  {
    id: "template6key",
    schema: {
      type: "object",
      serviceType: "Dodatečná konzultace",
      title: "Follow up consultation",
      description: "",
      properties: {
        notes: {
          type: "string",
          medicalDocumentation: "yes",
        },
        fixedText: {
          type: "string",
          medicalDocumentation: "yes",
        },
      },
      required: ["notes"],
    },
    uischema: {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/notes",
          label: "Notes",
          options: { multi: true },
        },
        {
          type: "Control",
          scope: "#/properties/fixedText",
          label: "Fixed Text",
          options: { multi: true },
        },
      ],
    },
  },
  {
    id: "template5key",
    schema: {
      type: "object",
      serviceType: "Debonding fixního aparátu",
      title: "Debonding of fixed retainers",
      description: "",
      properties: {
        debondingFixedRetainer: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        notes: {
          type: "string",
          medicalDocumentation: "yes",
        },
        opgTaken: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        reasonIfNoOpg: {
          type: "string",
          medicalDocumentation: "yes",
        },
        teleTaken: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        reasonIfNoTele: {
          type: "string",
          medicalDocumentation: "yes",
        },
        extraoralPhotosTaken: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        reasonIfNoExtraoralPhotos: {
          type: "string",
          medicalDocumentation: "yes",
        },
        intraoralPhotosTaken: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        reasonIfNoIntraoralPhotos: {
          type: "string",
          medicalDocumentation: "yes",
        },
        scanTaken: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        reasonIfNoScan: {
          type: "string",
          medicalDocumentation: "yes",
        },
        fixedText: {
          type: "string",
          medicalDocumentation: "yes",
        },
      },
      required: [
        "debondingFixedRetainer",
        "opgTaken",
        "reasonIfNoOpg",
        "teleTaken",
        "reasonIfNoTele",
        "extraoralPhotosTaken",
        "reasonIfNoExtraoralPhotos",
        "intraoralPhotosTaken",
        "reasonIfNoIntraoralPhotos",
        "scanTaken",
        "reasonIfNoScan",
      ],
    },
    uischema: {
      type: "VerticalLayout",
      elements: [
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/debondingFixedRetainer",
                  label: "Debonding Fixed Retainer",
                  options: { format: "radio" },
                },
                {
                  type: "Control",
                  scope: "#/properties/notes",
                  label: "Notes",
                  options: { multi: true },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/opgTaken",
                  label: "OPG Taken",
                  options: { format: "radio" },
                },
                {
                  type: "Control",
                  scope: "#/properties/reasonIfNoOpg",
                  label: "Reason (if no OPG)",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/opgTaken",
                      schema: { const: "No" },
                    },
                  },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/teleTaken",
                  label: "TELE Taken",
                  options: { format: "radio" },
                },
                {
                  type: "Control",
                  scope: "#/properties/reasonIfNoTele",
                  label: "Reason (if no TELE)",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/teleTaken",
                      schema: { const: "No" },
                    },
                  },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/extraoralPhotosTaken",
                  label: "Extraoral Photos Taken",
                  options: { format: "radio" },
                },
                {
                  type: "Control",
                  scope: "#/properties/reasonIfNoExtraoralPhotos",
                  label: "Reason (if no extraoral photos)",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/extraoralPhotosTaken",
                      schema: { const: "No" },
                    },
                  },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/intraoralPhotosTaken",
                  label: "Intraoral Photos Taken",
                  options: { format: "radio" },
                },
                {
                  type: "Control",
                  scope: "#/properties/reasonIfNoIntraoralPhotos",
                  label: "Reason (if no intraoral photos)",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/intraoralPhotosTaken",
                      schema: { const: "No" },
                    },
                  },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/scanTaken",
                  label: "Scan Taken",
                  options: { format: "radio" },
                },
                {
                  type: "Control",
                  scope: "#/properties/reasonIfNoScan",
                  label: "Reason (if no scan)",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/scanTaken",
                      schema: { const: "No" },
                    },
                  },
                },
              ],
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "Control",
              scope: "#/properties/fixedText",
              label: "Fixed Text",
              options: { multi: true },
            },
            {
              type: "Label",
              text: "",
            },
            {
              type: "Label",
              text: "",
            },
            {
              type: "Label",
              text: "",
            },
            {
              type: "Label",
              text: "",
            },
            {
              type: "Label",
              text: "",
            },
          ],
        },
      ],
    },
  },
  {
    id: "template3key",
    schema: {
      type: "object",
      serviceType: "Scan",
      title: "Scan",
      description: "",
      properties: {
        scanTaken: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        reasonIfNoScan: {
          type: "string",
          medicalDocumentation: "yes",
        },
        notes: {
          type: "string",
          medicalDocumentation: "yes",
        },
        fixedText: {
          type: "string",
          medicalDocumentation: "yes",
        },
      },
      required: ["scanTaken", "reasonIfNoScan"],
    },
    uischema: {
      type: "VerticalLayout",
      elements: [
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/scanTaken",
                  label: "Scan zubů",
                  options: { format: "radio" },
                },
                {
                  type: "HorizontalLayout",
                  elements: [
                    {
                      type: "Control",
                      scope: "#/properties/reasonIfNoScan",
                      label: "Reason (if no scan)",
                      options: { multi: true },
                      rule: {
                        effect: "SHOW",
                        condition: {
                          scope: "#/properties/scanTaken",
                          schema: { const: "No" },
                        },
                      },
                    },
                    {
                      type: "Label",
                      text: "",
                    },
                    {
                      type: "Label",
                      text: "",
                    },
                    {
                      type: "Label",
                      text: "",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "Control",
              scope: "#/properties/notes",
              label: "Notes",
              options: { multi: true },
            },
            {
              type: "Control",
              scope: "#/properties/fixedText",
              label: "Fixed Text",
              options: { multi: true },
            },
          ],
        },
      ],
    },
  },
  {
    id: "template4key",
    schema: {
      type: "object",
      serviceType: "Rentgen",
      title: "Rentgen",
      description: "",
      properties: {
        rtgTaken: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        teleTaken: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        notes: {
          type: "string",
          medicalDocumentation: "yes",
        },
        fixedText: {
          type: "string",
          medicalDocumentation: "yes",
        },
      },
      required: ["rtgTaken", "teleTaken"],
    },
    uischema: {
      type: "VerticalLayout",
      elements: [
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "Control",
              scope: "#/properties/rtgTaken",
              label: "RTG taken",
              options: { format: "radio" },
            },
            {
              type: "Control",
              scope: "#/properties/teleTaken",
              label: "TELE Taken",
              options: { format: "radio" },
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "Control",
              scope: "#/properties/notes",
              label: "Notes",
              options: { multi: true },
            },
            {
              type: "Control",
              scope: "#/properties/fixedText",
              label: "Fixed Text",
              options: { multi: true },
            },
          ],
        },
      ],
    },
  },
  {
    id: "template2key",
    schema: {
      type: "object",
      serviceType: "Vstupní vyšetření",
      title: "Entry Examination",
      description: "",
      properties: {
        opgTaken: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        reasonIfNoOpg: {
          type: "string",
          medicalDocumentation: "yes",
        },
        teleTaken: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        reasonIfNoTele: {
          type: "string",
          medicalDocumentation: "yes",
        },
        extraoralPhotosTaken: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        reasonIfNoExtraoralPhotos: {
          type: "string",
          medicalDocumentation: "yes",
        },
        intraoralPhotosTaken: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        reasonIfNoIntraoralPhotos: {
          type: "string",
          medicalDocumentation: "yes",
        },
        scanTaken: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        reasonIfNoScan: {
          type: "string",
          medicalDocumentation: "yes",
        },
        fixedText: {
          type: "string",
          medicalDocumentation: "yes",
        },
      },
      required: [
        "opgTaken",
        "reasonIfNoOpg",
        "teleTaken",
        "reasonIfNoTele",
        "extraoralPhotosTaken",
        "reasonIfNoExtraoralPhotos",
        "intraoralPhotosTaken",
        "reasonIfNoIntraoralPhotos",
        "scanTaken",
        "reasonIfNoScan",
      ],
    },
    uischema: {
      type: "VerticalLayout",
      elements: [
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/opgTaken",
                  label: "Zhotoveno OPG",
                  options: { format: "radio" },
                },
                {
                  type: "Control",
                  scope: "#/properties/reasonIfNoOpg",
                  label: "Reason (if no OPG)",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/opgTaken",
                      schema: { const: "No" },
                    },
                  },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/teleTaken",
                  label: "TELE taken",
                  options: { format: "radio" },
                },
                {
                  type: "Control",
                  scope: "#/properties/reasonIfNoTele",
                  label: "Reason (if no Tele)",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/teleTaken",
                      schema: { const: "No" },
                    },
                  },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/extraoralPhotosTaken",
                  label: "Extraoral Photos Taken",
                  options: { format: "radio" },
                },
                {
                  type: "Control",
                  scope: "#/properties/reasonIfNoExtraoralPhotos",
                  label: "Reason (if no extraoral photos)",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/extraoralPhotosTaken",
                      schema: { const: "No" },
                    },
                  },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/intraoralPhotosTaken",
                  label: "Intraoral Photos Taken",
                  options: { format: "radio" },
                },
                {
                  type: "Control",
                  scope: "#/properties/reasonIfNoIntraoralPhotos",
                  label: "Reason (if no intraoral photos)",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/intraoralPhotosTaken",
                      schema: { const: "No" },
                    },
                  },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/scanTaken",
                  label: "Scan Taken",
                  options: { format: "radio" },
                },
                {
                  type: "Control",
                  scope: "#/properties/reasonIfNoScan",
                  label: "Reason (if no scan)",
                  options: { multi: true },
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/scanTaken",
                      schema: { const: "No" },
                    },
                  },
                },
              ],
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "Control",
              scope: "#/properties/fixedText",
              label: "Fixed Text",
              options: { multi: true },
            },
            {
              type: "Label",
              text: "",
            },
            {
              type: "Label",
              text: "",
            },
            {
              type: "Label",
              text: "",
            },
            {
              type: "Label",
              text: "",
            },
          ],
        },
      ],
    },
  },
  {
    id: "template1key",
    schema: {
      type: "object",
      serviceType: "Scan před konzultací",
      title: "Scan Before Consultation",
      description: "",
      properties: {
        scanTaken: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes" },
            { const: "No", title: "No" },
          ],
          medicalDocumentation: "yes",
        },
        reasonIfNoScan: {
          type: "string",
          medicalDocumentation: "yes",
        },
        intraoralPhotosTaken: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        reasonIfNoIntraoralPhotos: {
          type: "string",
          medicalDocumentation: "yes",
        },
        hasFixedRetainer: {
          type: "string",
          oneOf: [
            { const: "Yes", title: "Yes", i18n: "yes" },
            { const: "No", title: "No", i18n: "no" },
          ],
          medicalDocumentation: "yes",
        },
        notes: {
          type: "string",
          medicalDocumentation: "yes",
        },
        fixedText: {
          type: "string",
          medicalDocumentation: "yes",
        },
      },
      required: [
        "scanTaken",
        "reasonIfNoScan",
        "intraoralPhotosTaken",
        "reasonIfNoIntraoralPhotos",
        "hasFixedRetainer",
      ],
    },
    uischema: {
      type: "VerticalLayout",
      elements: [
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/scanTaken",
                  label: "Scan zubů",
                  options: { format: "radio" },
                },
                {
                  type: "Control",
                  scope: "#/properties/reasonIfNoScan",
                  label: "Reason (if no scan)",
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/scanTaken",
                      schema: { const: "No" },
                    },
                  },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/intraoralPhotosTaken",
                  label: "Intraoral Photos Taken",
                  options: { format: "radio" },
                },
                {
                  type: "Control",
                  scope: "#/properties/reasonIfNoIntraoralPhotos",
                  label: "Reason (if no photos)",
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/intraoralPhotosTaken",
                      schema: { const: "No" },
                    },
                  },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/hasFixedRetainer",
                  label: "Has Fixed Retainer",
                  options: { format: "radio" },
                },
              ],
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "Control",
              scope: "#/properties/notes",
              label: "Notes",
            },
            {
              type: "Control",
              scope: "#/properties/fixedText",
              label: "Fixed Text",
              options: { multi: true },
            },
          ],
        },
      ],
    },
  },
];

const AppointmentNotesTabContent = () => {
  const { t } = useTranslation(["translation", "taskTemplates"]);
  const [services, setServices] = useState({ short: [], long: [] });
  const [page] = useState({ limit: 60, offset: 0 });
  const { nurseNotes } = useWizardContext();
  const [formSubmitted, setFormSubmitted] = useState(false);

  const fetchServices = async () => {
    try {
      const result = await api.loadServices({ page });
      const newServices = result.data.services.data;
      if (newServices.length > 0) {
        const categorizedServices = newServices.reduce(
          (acc, service) => {
            if (service.duration <= SHORT_DURATION_THRESHOLD) {
              acc.short.push({
                name: service.name,
                id: service.id,
                template: mockedTaskTemplates[acc.short.length % 2],
              });
            } else {
              acc.long.push({
                name: service.name,
                id: service.id,
                template: mockedTaskTemplates[acc.long.length % 2],
              });
            }
            return acc;
          },
          { short: [], long: [] },
        );
        setServices(categorizedServices);
      }
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  useEffect(() => {
    fetchServices();
  }, [page]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    console.log("Form sent to receptionist:");
  };

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Typography variant="h4" sx={{ marginBottom: 3 }}>
        {t("appointmentNotes.title")}
      </Typography>

      <NotesForReceptionForm tasks={services} initialNotes={nurseNotes} />

      <Divider sx={{ marginTop: 4, marginBottom: 4 }} />

      <form onSubmit={handleSubmit} id="appointmentNotesForm">
        <AppointmentTasks tasks={services} formSubmitted={formSubmitted} />
      </form>

      <Grid2
        container
        spacing={3}
        alignItems="center"
        sx={{ justifyContent: "flex-end", marginTop: 2 }}
      >
        <Button
          variant="contained"
          color="primary"
          sx={{ alignSelf: "flex-end" }}
          form="appointmentNotesForm"
          type="submit"
        >
          {t("appointmentNotes.sendToReceptionist")}
        </Button>
      </Grid2>
    </Box>
  );
};

export default AppointmentNotesTabContent;
